import React, {
  isValidElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "gatsby";
import { GoogleApiWrapper } from "google-maps-react";
import { FindDealerContext } from "./context/findDealerContext";
import FindDealerMap from "./findDealerMap";
import view from "./findDealer.module.scss";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
const { siteMetadata } = require("../../../gatsby-config");
const googleAPIkey = siteMetadata.googleAPIkey;

const FindDealer = (props) => {
  //Define context
  const findDealerProps = useContext(FindDealerContext);

  /**
   * Define values from findDealerContext
   *
   * @param {method} searchZipCode calls three other functions from context setZipcode, isThereHistory, distanceRef
   * and passes the zipcode searched.
   */

  const {
    distanceRef,
    loadingList,
    setLoadingList,
    searchZipCode,
    setClosestDealerCompPresent,
    getDirections,
    scrollToListRef,
  } = findDealerProps;

  //If the reference is loaded it's safe to display list, set the state of loading to false
  useEffect(() => {
    if (updatedDataRef != null) {
      setLoadingList(false);
    }
  }, [updatedDataRef]);

  //Define states and context
  const searchMarker = <FontAwesomeIcon icon={faMapMarker} />;

  //Parsed data from the distanceRef since it's a string in localStorage
  const updatedDataRef = JSON.parse(distanceRef.current.newItem);

  const savedZip = updatedDataRef.searched_zip;

  const hashedDealerList = JSON.parse(localStorage.getItem(`hashList${siteMetadata.site_id}`))

  useEffect(() => {
    if (isValidElement(<DealerList />)) {
      setClosestDealerCompPresent(true);
    }
  });

  function checkSellLink(dealer) {
    if (props.isSellYourVehicle) 
      return (props.isSellYourVehicle && dealer.sellYourCarURL != '');
    else 
      return true;
  }

  function filterFixedDealers(dealer) {
    let foundDealerObj = hashedDealerList.find(item => item.dealerName === dealer.dealerName)
    return props.fixedDealerList.some(item => item === foundDealerObj.dealerId)
  }

  const DealerList = () => (props.fixedDealerList && props.fixedDealerList?.length > 0) ? 
  updatedDataRef.closest_dealers.filter(checkSellLink).filter(filterFixedDealers).map((dealer, i) => {
    let foundDealer = props.dealerLocals.find((el) =>
      el.dealerName === dealer.dealerName
    );
    return (
      <li className={view["dealer__container"]} key={i}>
        <div className={view["list-title"]}>
          <h4 className={view["dealer__name"]}>{dealer.dealerName}</h4>
        </div>
        {dealer.distances
          ? (
            <p className={view["dealer__distance"]}>
              {dealer.distances.distance.text} Away
            </p>
          )
          : null}
        <div className={view["dealer__address"]}>
          <p>
            {dealer.destination ? dealer.destination : dealer.dealerAddress}
            <a
              onClick={() =>
                getDirections(
                  dealer.destination
                    ? dealer.destination
                    : dealer.dealerAddress,
                )}
            >
              <span className={view["get-directions"]}>Get Directions</span>
            </a>
          </p>
        </div>
        <div className={view["dealer__phone"]}>
          <p>{foundDealer.dealerPhoneNumber}</p>
        </div>
        <div className={view["dealer__buttons__container"]}>
          <Link
            className={view["dealer__button"]}
            to={`${foundDealer.websiteLink}`}
          >
            VISIT WEBSITE
          </Link>
          <Link
            className={view["dealer__button"]}
            to={`${(props.isSellYourVehicle ? foundDealer.sellYourCarURL : foundDealer.inventoryURL)}`}
          >
            {props.isSellYourVehicle ? 'SELL YOUR CAR' : 'VIEW INVENTORY'}
          </Link>
        </div>
      </li>
    );
  }) : updatedDataRef.closest_dealers.filter(checkSellLink).map((dealer, i) => {
    let foundDealer = props.dealerLocals.find((el) =>
      el.dealerName === dealer.dealerName
    );
    return (
      <li className={view["dealer__container"]} key={i}>
        <div className={view["list-title"]}>
          <h4 className={view["dealer__name"]}>{dealer.dealerName}</h4>
        </div>
        {dealer.distances
          ? (
            <p className={view["dealer__distance"]}>
              {dealer.distances.distance.text} Away
            </p>
          )
          : null}
        <div className={view["dealer__address"]}>
          <p>
            {dealer.destination ? dealer.destination : dealer.dealerAddress}
            <a
              onClick={() =>
                getDirections(
                  dealer.destination
                    ? dealer.destination
                    : dealer.dealerAddress,
                )}
            >
              <span className={view["get-directions"]}>Get Directions</span>
            </a>
          </p>
        </div>
        <div className={view["dealer__phone"]}>
          <p>{foundDealer.dealerPhoneNumber}</p>
        </div>
        <div className={view["dealer__buttons__container"]}>
          <Link
            className={view["dealer__button"]}
            to={`${foundDealer.websiteLink}`}
          >
            VISIT WEBSITE
          </Link>
          <Link
            className={view["dealer__button"]}
            to={`${(props.isSellYourVehicle ? foundDealer.sellYourCarURL : foundDealer.inventoryURL)}`}
          >
            {props.isSellYourVehicle ? 'SELL YOUR CAR' : 'VIEW INVENTORY'}
          </Link>
        </div>
      </li>
    );
  });
  

  const DealerSearch = () => {
    const [formZip, setFormZip] = useState("");
    const searchHeightRef = useRef();
    const [searchBarHeight, setSearchBarHeight] = useState("");

    useEffect(() => {
      const divElement = searchHeightRef.current;
      setSearchBarHeight(divElement?.clientHeight);
    }, []);

    function holdFormZip(val) {
      setFormZip(val);
    }

    const renderSearchBar = () => {
      if (props.isDealerLocator || props.isSellYourVehicle) return;
      return (
        <form
          ref={searchHeightRef}
          className={view["search-cont"]}
          onSubmit={() => searchZipCode(formZip)}
        >
          <input
            className={view["search-dir"]}
            placeholder={savedZip
              ? "Showing dealers closest to " + savedZip
              : "Enter Zip Code"}
            maxlength="5"
            style={{ display: "block", width: "100%" }}
            required
            name="setZip"
            label="zip code"
            type="text"
            wrap="hard"
            onChange={(e) => holdFormZip(e.target.value)}
          />
          <button
            className={view["search-btn"]}
            onClick={(e) => (e.preventDefault(), searchZipCode(formZip))}
          >
            <p style={{ position: "relative", top: "-2px", color: "#f4f5f7" }}>
              &#x2315;
            </p>
          </button>
        </form>
      );
    };

    const renderList = () => {
      if (loadingList) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Loader type="TailSpin" color="blue" height={80} width={80} />
          </div>
        );
      }
      return (
        <ul
          className={view["search-list"]}
          style={{
            height: `calc(100% - ${
              (props.isDealerLocator || props.isSellYourVehicle) ? "0px" : "46px"
            })`,
          }}
        >
          <DealerList />
        </ul>
      );
    };

    return (
      <div className={view["left-wrap"]}>
        {renderSearchBar()}
        {renderList()}
      </div>
    );
  };

  const renderTitle = () => {
    if (props.isDealerLocator || props.isSellYourVehicle) return;
    return (
      <>
        <h3 style={{ textAlign: "center" }}>
          ENTER <span className={view["border-span"]}>ZIP</span> CODE
        </h3>
        <h2 style={{ textAlign: "center", marginTop: 25, fontSize: "2.5rem" }}>
          Find a Dealer
        </h2>
      </>
    );
  };

  return (
    <div
      style={{ position: "relative", display: "block" }}
      ref={scrollToListRef}
    >
      {renderTitle()}
      <div className={view["directions"]}>
        <div className={view["dir-left"]}>
          <DealerSearch />
        </div>
        <div className={view["dir-right"]}>
          <div
            className={view["right-wrap"]}
            style={{ position: "relative", height: "100%", width: "100%" }}
          >
            <FindDealerMap props={props} hashedDealerList={hashedDealerList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: (googleAPIkey), // Google Maps API Key
})(FindDealer);
